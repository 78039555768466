
<template>
  <div class="perform-setting">
    <van-cell-group title="执行设置">
      <van-form ref="refPerformForm">
        <van-field
          readonly
          clickable
          name="repeatPicker"
          v-model="repeatType"
          label="执行频率"
          placeholder="点击选择重复类型"
          @click="showRepeatPicker = true"
          :rules="[{ required: true }]"
        />
        <!-- <template v-if="repeatType === '间隔'">
      <van-field
        readonly
        clickable
        name="intervalPicker"
        :value="intervalDateArray.join('')"
        label="间隔时间"
        placeholder="点击选择具体详情"
        @click="showIntervalPicker = true"
      />
      <van-popup v-model="showIntervalPicker" position="bottom" get-container="body">
        <van-picker
          show-toolbar
          title="请选择间隔时间"
          :columns="intervalColumns"
          @confirm="onIntervalConfirm"
          @cancel="showIntervalPicker = false"/>
      </van-popup>
    </template> -->

        <template v-if="repeatType === '不重复'">
          <van-field
            readonly
            clickable
            name="datePicker"
            :value="dateValue"
            label="日期详情"
            placeholder="点击选择具体详情"
            @click="showDatePicker = true"
            :rules="[{ required: true }]"
          />
          <!-- <van-action-sheet
          v-model="showDatePicker"
          close-on-click-action
          get-container="body"
          title="日期选择"
          class="date-picker"> -->
          <van-calendar
            v-model="showDatePicker"
            get-container="body"
            type="single"
            color="#1989fa"
            @confirm="onDateConfirm" />
          <!-- <van-calendar
            type="multiple"
            color="#1989fa"
            :readonly="false"
            :poppable="false"
            :show-mark="false"
            :show-title="false"
            :min-date="new Date(2021, 2, 1)"
            :max-date="new Date(2021, 2, 31)"
            first-day-of-week="1"
            @confirm="onDateConfirm"
          /> -->
        <!-- <van-picker
          v-model="currentDate"
          show-toolbar
          type="month-day"
        /> -->
        <!-- </van-action-sheet> -->
        </template>
        <template v-if="repeatType === '每周'">
          <van-field
            readonly
            clickable
            name="weekPicker"
            :value="weeksTypeArray.join('、')"
            label="日期详情"
            placeholder="点击选择具体详情"
            @click="showWeekPicker = true"
            :rules="[{ required: true}]"
          />
          <!-- <van-popup v-model="showWeekPicker" position="bottom" get-container="body"> -->
          <van-action-sheet
            v-model="showWeekPicker"
            close-on-click-action
            get-container="body"
            title="每周"
            class="week-picker">
            <!-- <van-picker
          show-toolbar
          :columns="weekColumns"
          @confirm="onWeekConfirm"
          @cancel="showWeekPicker = false"
        /> -->
            <van-tree-select
              :items="weekColumns"
              :active-id.sync="activeWeekIds"
              :main-active-index.sync="activeWeekIndex"
            />
            <div style="margin: 16px;">
              <van-button round block type="primary" @click="onWeekConfirm">确定</van-button>
            </div>
          </van-action-sheet>
          <!-- </van-popup> -->
        </template>
        <template v-if="repeatType === '每月'">
          <van-field
            readonly
            clickable
            name="monthPicker"
            :value="monthDateArray.join('、')"
            label="日期详情"
            placeholder="点击选择具体详情"
            @click="showMonthPicker = true"
            :rules="[{ required: true }]"
          />
          <van-action-sheet
            v-model="showMonthPicker"
            close-on-click-action
            get-container="body"
            title="日期选择"
            class="month-picker">
            <!-- <van-tree-select
          height="55vw"
          :items=" [
            { text: '1', children: [
              { text: '浙江', id: 1},
              { text: '江苏', id: 2},
            ]},
            { text: '2', children: [
              { text: '浙江', id: 3},
              { text: '江苏', id: 4},
            ]}
          ]"
          :active-id.sync="activeIds"
          :main-active-index.sync="activeIndex"
        /> -->
            <!-- <van-checkbox-group v-model="checkboxGroup" direction="horizontal">
            <van-checkbox name="a">每月最后一天</van-checkbox>
            <van-checkbox name="b">每月最后一个工作日</van-checkbox>
          </van-checkbox-group> -->
            <van-calendar
              type="multiple"
              color="#1989fa"
              :readonly="false"
              :poppable="false"
              :show-mark="false"
              :show-title="false"
              :default-date="monthDefaultDate"
              :min-date="new Date(2021, 2, 1)"
              :max-date="new Date(2021, 2, 31)"
              first-day-of-week="1"
              @confirm="onMonthConfirm"
            />
            <!-- <van-picker
          v-model="currentDate"
          show-toolbar
          type="month-day"
        /> -->
          </van-action-sheet>
        </template>
        <template v-if="repeatType === '每季度'">
          <van-field
            readonly
            clickable
            name="quarterPicker"
            :value="quarterDateArray.length ? `${quarterColumns[activeQuarterIndex].text}(${quarterDateArray.join('、')})` : ''"
            label="日期详情"
            placeholder="点击选择具体详情"
            @click="showQuarterPicker = true"
            :rules="[{ required: true }]"
          />
          <van-action-sheet
            v-model="showQuarterPicker"
            close-on-click-action
            get-container="body"
            title="每季度"
            class="quarter-picker">
            <van-tree-select
              :items="quarterColumns"
              :active-id.sync="activeQuarterIds"
              :main-active-index.sync="activeQuarterIndex"
            >
              <template #content>
                <!-- <van-checkbox-group v-model="checkboxGroup" direction="horizontal">
                <van-checkbox name="a">最后一天</van-checkbox>
                <van-checkbox name="b">最后一个工作日</van-checkbox>
              </van-checkbox-group> -->
                <van-calendar
                  type="multiple"
                  color="#1989fa"
                  class="quarter-calendar"
                  :readonly="false"
                  :poppable="false"
                  :show-mark="false"
                  :show-title="false"
                  :default-date="quarterDefaultDate"
                  :min-date="new Date(2021, 2, 1)"
                  :max-date="new Date(2021, 2, 31)"
                  first-day-of-week="1"
                  @confirm="onQuarterConfirm"
                />
              </template>
            </van-tree-select>
          </van-action-sheet>
        </template>

        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="repeatType === '每小时'? minuteTime: dateTime"
          label="开始时间"
          placeholder="点击选择时间"
          @click="showTimePicker = true"
          :rules="[{ required: true }]"
        />
      </van-form>

      <van-popup v-model="showRepeatPicker" position="bottom" get-container="body" :close-on-popstate="true">
        <van-picker
          show-toolbar
          :columns="repeatColumns"
          @confirm="onRepeatConfirm"
          @cancel="showRepeatPicker = false"
        />
      </van-popup>
      <van-popup v-model="showTimePicker" position="bottom" get-container="body" :close-on-popstate="true">
        <van-picker
          v-if="repeatType === '每小时'"
          title="请选择分钟数"
          show-toolbar
          :columns="minuteColumns"
          :value="minuteTime"
          @confirm="onTimeConfirm"
          @cancel="showTimePicker = false"
        />
        <van-datetime-picker
          v-else
          type="time"
          title="请选择时间"
          :value="dateTime"
          @confirm="onTimeConfirm"
          @cancel="showTimePicker = false"
        />
      </van-popup>
    </van-cell-group>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'PerformSetting',
  data () {
    return {
      showTimePicker: false,
      dateTime: moment().format('HH:mm'),
      minuteTime: '',
      repeatType: '每小时',
      minuteColumns: [],
      // repeatType: '不重复',
      showRepeatPicker: false,
      repeatColumns: ['每小时', '每天', '每周', '每月', '每季度'],
      // repeatColumns: ['不重复', '每小时', '每天', '每周', '每月', '每季度'],
      showDatePicker: false,
      // dateArray: [],
      weeksTypeArray: [],
      showWeekPicker: false,
      weekColumns: [{
        text: '每周',
        children: [
          { text: '周一', id: 0, word: 'MON' },
          { text: '周二', id: 1, word: 'Tue' },
          { text: '周三', id: 2, word: 'Wed' },
          { text: '周四', id: 3, word: 'Thu' },
          { text: '周五', id: 4, word: 'Fri' },
          { text: '周六', id: 5, word: 'Sat' },
          { text: '周日', id: 6, word: 'Sun' }
        ]
      }],
      activeWeekIndex: 0,
      activeWeekIds: [],
      monthDateArray: [],
      monthDefaultDate: null,
      showMonthPicker: false,
      showQuarterPicker: false,
      activeQuarterIndex: 0,
      activeQuarterIds: [],
      quarterDateArray: [],
      quarterDefaultDate: null,
      quarterColumns: [{ text: '第一个月份' }, { text: '第二个月份' }, { text: '第三个月份' }],
      checkboxGroup: [],
      result: {
        second: {
          singleNumber: 0 // 默认为0
        },
        minute: {
          singleNumber: moment().format('HH:mm').split(':')[1][0] === '0' ? moment().format('HH:mm').split(':')[1][1] : moment().format('HH:mm').split(':')[1]
        },
        hour: {
          singleNumber: moment().format('HH:mm').split(':')[0][0] === '0' ? moment().format('HH:mm').split(':')[0][1] : moment().format('HH:mm').split(':')[0]
        },
        day: {
          singleNumber: new Date().getDate(),
          specificSpecific: []
        },
        week: {
          specificSpecific: [],
          singleNumber: ''
        },
        month: {
          singleNumber: new Date().getMonth() + 1,
          incrementStart: 0,
          incrementIncrement: 0
        },
        year: {
          singleNumber: new Date().getFullYear()
        }
      }
    }
  },
  computed: {
    dateValue () {
      if (this.result.year.singleNumber && this.result.month.singleNumber && this.result.day.singleNumber) {
        return this.result.year.singleNumber + '-' + this.result.month.singleNumber + '-' + this.result.day.singleNumber
      } else {
        return ''
      }
    },
    hoursText () {
      let hours = ''
      switch (this.repeatType) {
        case '不重复':
        case '每天':
        case '每周':
        case '每月':
        case '每季度':
          hours = this.result.hour.singleNumber
          break
        default:
          hours = '*'
      }
      return hours
    },
    daysText () {
      let days = ''
      switch (this.repeatType) {
        case '不重复':
          days = this.result.day.singleNumber
          break
        case '每周':
          days = '?'
          break
        case '每月':
          days = this.result.day.specificSpecific.join(',')
          break
        case '每季度':
          days = this.result.day.specificSpecific.join(',')
          break
        default:
          days = '*'
      }
      return days
    },
    weeksText () {
      let weeks = ''
      switch (this.repeatType) {
        case '每周':
          weeks = this.result.week.specificSpecific.join(',')
          break
        default:
          weeks = '?'
      }
      return weeks
    },
    monthsText () {
      let months = ''
      switch (this.repeatType) {
        case '不重复':
          months = this.result.month.singleNumber
          break
        case '每季度':
          months = this.result.month.incrementStart + '/' + this.result.month.incrementIncrement
          break
        default:
          months = '*'
      }
      return months
    },
    yearsText () {
      let years = ''
      switch (this.repeatType) {
        case '不重复':
          years = this.result.year.singleNumber
          break
        default:
          years = ''
      }
      return years
    },
    cron () {
      return `${this.result.second.singleNumber} ${this.result.minute.singleNumber} ${this.hoursText} ${this.daysText} ${this.monthsText} ${this.weeksText} ${this.yearsText}`
    },
    performValidateStr () {
      let name = ''
      switch (this.repeatType) {
        // case '不重复':
        //   name = 'datePicker'
        //   break
        case '每周':
          name = 'weekPicker'
          break
        case '每月':
          name = 'monthPicker'
          break
        case '每季度':
          name = 'quarterPicker'
          break
      }
      return name
    }
  },
  props: {
    editCron: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean
    }
  },
  mounted () {
    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 10; j++) {
        this.minuteColumns.push('' + i + j)
      }
    }
    console.log('getCornMess', this.cron)
    if (this.editCron) {
      console.log('editCron', this.editCron)
      this.formatEditCron()
    }
    this.$emit('getCornMess', this.cron, this.performValidateStr)
  },
  methods: {
    onTimeConfirm (value) {
      console.log('value', value)
      if (value.split(':')[1]) {
        this.dateTime = value
        this.result.minute.singleNumber = value.split(':')[1][0] === '0' ? value.split(':')[1][1] : value.split(':')[1]
        this.result.hour.singleNumber = value.split(':')[0][0] === '0' ? value.split(':')[0][1] : value.split(':')[0]
      } else {
        this.minuteTime = value + '分'
        this.result.minute.singleNumber = value[0] === '0' ? value[1] : value
      }
      this.showTimePicker = false
    },
    onRepeatConfirm (value) {
      this.repeatType = value
      this.showRepeatPicker = false
    },
    onWeekConfirm() {
      this.weeksTypeArray.splice(0)
      this.result.week.specificSpecific.splice(0)
      this.activeWeekIds.sort()
      const weekColumnsChildren = this.weekColumns[0].children
      this.activeWeekIds.forEach(item => {
        this.weeksTypeArray.push(weekColumnsChildren[item].text)
        this.result.week.specificSpecific.push(weekColumnsChildren[item].word)
      })
      this.showWeekPicker = false
    },
    onMonthConfirm(date) {
      this.monthDateArray.splice(0)
      let dateArray = []
      date.forEach(item => {
        dateArray.push(item.getDate())
      })
      dateArray = dateArray.sort(function(a, b) {
        return a - b
      })
      console.log(dateArray)
      this.result.day.specificSpecific = [...dateArray]
      dateArray.forEach(item => {
        this.monthDateArray.push(item + '号')
      })
      console.log('date', date)
      this.showMonthPicker = false
    },
    onQuarterConfirm (date) {
      console.log('confirem', date)
      this.quarterDateArray.splice(0)
      let dateArray = []
      date.forEach(item => {
        dateArray.push(item.getDate())
      })
      dateArray = dateArray.sort(function(a, b) {
        return a - b
      })
      console.log(dateArray)
      this.result.day.specificSpecific = [...dateArray]
      this.result.month.incrementStart = this.activeQuarterIndex + 1
      this.result.month.incrementIncrement = 3
      dateArray.forEach(item => {
        this.quarterDateArray.push(item + '号')
      })
      console.log('this.quarterDateArray', this.quarterDateArray)
      this.showQuarterPicker = false
    },
    onDateConfirm (date) {
      this.result.year.singleNumber = date.getFullYear()
      this.result.month.singleNumber = date.getMonth() + 1
      this.result.day.singleNumber = date.getDate()
      this.showDatePicker = false
    },
    formatEditCron () {
      const cronArray = this.editCron.split(' ')
      this.result.minute['singleNumber'] = cronArray[1]
      this.result.hour['singleNumber'] = cronArray[2]
      this.result.day['singleNumber'] = cronArray[3]
      this.result.month['singleNumber'] = cronArray[4]
      this.result.week['singleNumber'] = cronArray[5]
      this.result.year['singleNumber'] = cronArray[6]
      let dataMinuteStr = this.result.minute['singleNumber']
      let dataHourStr = this.result.hour['singleNumber']
      console.log('this.result.minute', this.result.minute['singleNumber'])
      if (this.result.minute['singleNumber'].length === 1) {
        dataMinuteStr = '0' + this.result.minute['singleNumber']
      }
      if (this.result.hour['singleNumber'].length === 1 && this.result.hour['singleNumber'] !== '*') {
        dataHourStr = '0' + this.result.hour['singleNumber']
      } else if (this.result.hour['singleNumber'] === '*') {
        dataHourStr = '00'
      }
      this.dateTime = dataHourStr + ':' + dataMinuteStr
      this.onTimeConfirm(this.dateTime)
      if (cronArray[2] === '*') {
        this.repeatType = '每小时'
        this.minuteTime = dataMinuteStr + '分'
      } else if (cronArray[3] === '*') {
        this.repeatType = '每天'
      } else if (cronArray[3] === '?') {
        this.repeatType = '每周'
        this.result.week['singleNumber'] = ''
        this.result.week['specificSpecific'] = cronArray[5].split(',')
        this.weeksTypeArray.splice(0)
        this.activeWeekIds.splice(0)
        const weekColumnsChildren = this.weekColumns[0].children
        this.result.week['specificSpecific'].forEach(item => {
          const weekItem = weekColumnsChildren.find(week => week.word === item)
          this.weeksTypeArray.push(weekItem.text)
          this.activeWeekIds.push(weekItem.id)
        })
      } else if (cronArray[4] === '*') {
        this.repeatType = '每月'
        this.result.day['singleNumber'] = ''
        this.result.day['specificSpecific'] = cronArray[3].split(',')
        this.monthDateArray.splice(0)
        this.monthDefaultDate = []
        this.result.day['specificSpecific'].forEach(item => {
          this.monthDateArray.push(item + '号')
          const date = new Date()
          date.setFullYear(2021) // 年月是固定的，仅为了展示1-31号供选择
          date.setMonth(2)
          date.setDate(item)
          this.monthDefaultDate.push(date)
        })
        console.log('monthDefaultDate', this.monthDefaultDate)
      } else if (cronArray[6] === '') {
        this.repeatType = '每季度'
        this.result.month['singleNumber'] = ''
        this.result.month['incrementStart'] = cronArray[4].split('/')[0]
        this.result.month['incrementIncrement'] = cronArray[4].split('/')[1]
        this.result.day['singleNumber'] = ''
        this.result.day['specificSpecific'] = cronArray[3].split(',')
        this.activeQuarterIndex = this.result.month['incrementStart'] - 1
        this.quarterDateArray.splice(0)
        this.quarterDefaultDate = []
        this.result.day['specificSpecific'].forEach(item => {
          this.quarterDateArray.push(item + '号')
          const date = new Date()
          date.setFullYear(2021) // 年月是固定的，仅为了展示1-31号供选择
          date.setMonth(2)
          date.setDate(item)
          this.quarterDefaultDate.push(date)
        })
      } else {
        this.repeatType = '不重复'
      }

      console.log('cronArray', cronArray)
      console.log('result', this.result)
    }
  },
  watch: {
    cron: {
      handler() {
        console.log('getCornMess', this.cron)
        this.$emit('getCornMess', this.cron, this.performValidateStr)
      },
      deep: true
    },
    visible (newVal) {
      if (newVal) {
        if (this.editCron) {
          console.log('editCron', this.editCron)
          this.formatEditCron()
        }
      }
    },
    showRepeatPicker (newVal) {
      if (newVal) {
        window.history.pushState({ title: '执行频率', url: '#' }, '执行频率', '#')
      } else if (history.state.title === '执行频率') {
        history.back()
      }
    },
    showTimePicker (newVal) {
      if (newVal) {
        window.history.pushState({ title: '执行时间', url: '#' }, '执行时间', '#')
      } else if (history.state.title === '执行时间') {
        history.back()
      }
    }
  }
}
</script>
<style lang="less" scope>
.perform-setting {
  .van-cell {
    padding: 5px 16px;
  }
  .van-cell-group__title {
    font-size: 12px;
    background-color: #f8f8f8;
    padding: 4px 16px;
  }
}
.week-picker {
  .van-action-sheet__content {
    .van-tree-select {
      .van-tree-select__nav {
        display: none;
      }
      .van-tree-select__content {
        // text-align: center;
        padding: 0 10%;
      }
    }
  }
}
.month-picker {
  .van-calendar {
    .van-calendar__header {
      display: none;
    }
  }
}
.quarter-picker {
  .quarter-calendar {
    .van-calendar__header {
      display: none;
    }
    .van-calendar__body {
      .van-calendar__day {
        // height: 40px;
      }
    }
  }
}
</style>
