
<template>
  <div class="condition-seting">
    <van-cell-group>
      <template #title>
        <div style="display: flex; justify-content: space-between;">
          <span>条件设置</span>
          <span @click="addSpaceCondition">
            <van-icon name="add-o" style="font-size:14px;vertical-align:middle;padding-right:2px;height:14px"/>添加
          </span>
        </div>
      </template>
      <template v-for="(conditionItem, index) in conditionsEdit['condition']">
        <van-field readonly :key="'condition' + index">
          <template #input>
            <van-field
              :value="conditionItem['message'] || ''"
              placeholder="请点击输入条件"
              @click="$refs['refConditionSettingpopup'].show(index,conditionItem)"
              style="padding: 0;"
              readonly/>
          </template>
          <template #button>
            <div @click="removeCondition(index)" style="font-size: 12px;">
              <van-icon name="delete-o" style="font-size: 14px; vertical-align: middle;padding-right:2px;" />删除
            </div>
          </template>
        </van-field>
        <van-field
          :key="'connection' + index"
          readonly
          v-if="index !== conditionsEdit['condition'].length - 1"
          name="connection"
          class="connection-field"
          @click="$refs['refRelationChoosePopup'].show()">
          <template #input>
            <van-tag plain :type="conditionsEdit['connection'] === '并且' ? 'primary' : 'success'">{{ conditionsEdit['connection'] }}</van-tag>
          </template>
        </van-field>
      </template>
    </van-cell-group>

    <RelationChoosePopup @onConnectionConfirm="changeConnection" ref="refRelationChoosePopup"/>
    <ConditionSettingpopup :metricsArray="metricsArray" @onSubmitCondition="addCondition" ref="refConditionSettingpopup" />
  </div>
</template>
<script>
import ConditionSettingpopup from './ConditionSettingpopup.vue'
import RelationChoosePopup from './RelationChoosePopup.vue'

export default {
  name: 'ConditionSetting',
  data () {
    return {
      // visible: false,
      conditionsEdit: {
        condition: [{
          metrics: '',
          operator: '',
          operatorCode: '',
          threshold1: '',
          threshold2: '',
          message: ''
        }],
        connection: '并且'
      },
      showCreateCondition: false,
      activeCollapseNames: [],
      connectionArray: [{ text: '大于', code: 'gt' }, { text: '小于', code: 'lt' }, { text: '等于', code: 'eq' },
        { text: '不等于', code: 'ne' }, { text: '大于等于', code: 'ge' }, { text: '小于等于', code: 'le' },
        { text: '在...和...之间', code: 'between' }]
    }
  },
  components: {
    ConditionSettingpopup,
    RelationChoosePopup
  },
  props: {
    metricsArray: {
      type: Array,
      default: () => {
        return []
      }
    },
    editConditions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    visible: {
      type: Boolean
    }
  },
  computed: {
  },
  mounted () {
    if (Object.keys(this.editConditions).length > 0) {
      this.formatEditCondition()
      this.$emit('conditionsEditChange', this.conditionsEdit)
    }
  },
  methods: {
    addCondition (conditionForm, index) {
      this.conditionsEdit['condition'][index]['metrics'] = conditionForm['metrics']
      this.conditionsEdit['condition'][index]['operator'] = conditionForm['operator']
      this.conditionsEdit['condition'][index]['operatorCode'] = conditionForm['operatorCode']
      this.conditionsEdit['condition'][index]['threshold1'] = conditionForm['threshold1']
      this.conditionsEdit['condition'][index]['threshold2'] = conditionForm['threshold2']
      this.conditionsEdit['condition'][index]['message'] = conditionForm['message']
    },
    changeConnection (value) {
      this.conditionsEdit['connection'] = value
    },
    removeCondition (index) {
      console.log('index', index)
      this.conditionsEdit['condition'].splice(index, 1)
    },
    addSpaceCondition () {
      this.conditionsEdit['condition'].push({
        metrics: '',
        operator: '',
        operatorCode: '',
        threshold1: '',
        threshold2: '',
        message: ''
      })
    },
    formatEditCondition () {
      const connectionKey = Object.keys(this.editConditions)[0]
      this.conditionsEdit = {
        condition: this.editConditions[connectionKey].map(condition => {
          const conditionObj = {
            metrics: condition.field,
            operatorCode: condition.op
          }
          if (condition.op === 'between') {
            conditionObj['operator'] = '在...和...之间'
            conditionObj['threshold1'] = condition.value[0]
            conditionObj['threshold2'] = condition.value[1]
            conditionObj['message'] = conditionObj.metrics + '在' + conditionObj.threshold1 + '和' + conditionObj.threshold2 + '之间'
          } else {
            conditionObj['threshold1'] = condition.value
            conditionObj['operator'] = this.connectionArray.find(connec => connec['code'] === conditionObj.operatorCode).text
            conditionObj['message'] = conditionObj.metrics + conditionObj.operator + conditionObj.threshold1
          }

          return conditionObj
        }),
        connection: connectionKey === 'and' ? '并且' : '或者'
      }

      // console.log('item.alertCondition[connectionKey]', this.editConditions[connectionKey])
      // console.log('conditionsEdit', this.conditionsEdit)
    }
  },
  watch: {
    conditionsEdit: {
      handler() {
        this.$emit('conditionsEditChange', this.conditionsEdit)
      },
      deep: true
    },
    visible (newVal) {
      if (newVal) {
        if (Object.keys(this.editConditions).length > 0) {
          console.log('editConditions', this.editConditions)
          this.formatEditCondition()
        }
      }
    }
  }
}
</script>
<style lang="less">
.condition-seting {
  .van-cell {
    padding: 5px 16px;
  }
  .van-cell-group__title {
    font-size: 12px;
    background-color: #f8f8f8;
    padding: 4px 16px;
  }
  .van-collapse-item__title--disabled { // 收缩面板标题
    color: #646566;
    // padding: 5px 16px;
  }
  .connection-field {
    // background-color: #f8fcff;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .connection-field-collapse {
    padding: 0;
  }
  .van-collapse-item__content { // 收缩面板内容
    background-color: #fdfdfd;
    padding: 0px 16px;
    div {
      color: #949494;
      background-color: #fdfdfd;
    }
    .van-field__label {
      padding-left: 10px;
    }
  }
}
</style>
