
<template>
  <div class="user-setting">
    <van-cell-group title="接收设置">
      <van-form ref="userSettingForm">
        <van-field
          readonly
          clickable
          name="targetType"
          :value="userSettingForm['targetType']"
          label="通知对象"
          right-icon="arrow-down"
          placeholder="请选择通知对象"
          :rules="[{ required: true}]"
          @click="sheetSelectType='targetType'; showFieldPicker = true"
        />
        <van-field
          readonly
          clickable
          name="channel"
          :value="userSettingForm['channel']"
          label="通知方式"
          right-icon="arrow-down"
          placeholder="请选择通知方式"
          :rules="[{ required: true}]"
          @click="sheetSelectType='channel'; showFieldPicker = true"
        />
        <van-field
          :readonly="!(this.userSettingForm['targetType'] === '' || this.userSettingForm['targetType'] === '自定义')"
          clickable
          name="target"
          :value="userSettingForm.target.join('、')"
          label="通知目标"
          :placeholder="targetPlaceholder"
          :rules="[{ required: true}]"
          @click="clickTargetField"
          @input="inputTargetField"
        />
      </van-form>
      <van-popup v-model="showFieldPicker" position="bottom" get-container="body" safe-area-inset-bottom :close-on-popstate="true">
        <van-picker
          show-toolbar
          :columns="pickerColumns"
          @confirm="onFieldPickerConfirm"
          @cancel="showFieldPicker = false"
        />
      </van-popup>
      <van-action-sheet
        v-model="targetPicker"
        close-on-click-action
        get-container="body"
        :title="targetPlaceholder"
        class="target-picker"
        :close-on-popstate="true">
        <van-search
          v-if="userSettingForm['targetType'] === '用户'"
          v-model="searchUserValue"
          show-action
          placeholder="请输入搜索关键词"
          @search="onUserSearch"
          @cancel="onUserSearchCancel"
        />
        <van-tree-select
          :items="targetColumns"
          :active-id.sync="activeTargetIds"
          :main-active-index.sync="activeTargetIndex"
        >
        </van-tree-select>
        <div style="margin: 16px;">
          <van-button round block type="primary" @click="onTargetConfirm">确定</van-button>
        </div>
      </van-action-sheet>
    </van-cell-group>
  </div>
</template>
<script>

export default {
  name: 'UserSetting',
  data () {
    return {
      usersArray: [],
      activeTargetIds: [],
      activeTargetIndex: 0,
      targetPicker: false,
      searchUserValue: '',
      userSettingForm: {
        targetType: '用户',
        channel: '系统通知',
        target: []
      },
      showFieldPicker: false,
      sheetSelectType: '',
      targetTypeArray: [],
      channelsArray: [],
      rolesArray: [],
      targetPlaceholder: '请输入通知目标'
    }
  },
  props: {
    editUserSetting: {
      type: Object,
      default: () => {
        return {}
      }
    },
    visible: {
      type: Boolean
    }
  },
  computed: {
    pickerColumns () {
      if (this.sheetSelectType === 'targetType') {
        return this.targetTypeArray.map(item => item.displayName)
      } else if (this.sheetSelectType === 'channel') {
        return this.channelsArray.map(item => item.displayName)
      }
      return []
    },
    targetColumns () {
      if (this.userSettingForm['targetType'] === '用户') {
        return [{
          text: '用户列表',
          children: JSON.parse(JSON.stringify(this.usersArray))
        }]
      } else if (this.userSettingForm['targetType'] === '角色') {
        return [{
          text: '角色列表',
          children: JSON.parse(JSON.stringify(this.rolesArray))
        }]
      } else {
        return [{
          text: '自定义列表',
          children: []
        }]
      }
    },
    formatData () {
      const channelItem = this.channelsArray.find(item => item.displayName === this.userSettingForm['channel'])
      const targetTypeItem = this.targetTypeArray.find(item => item.displayName === this.userSettingForm['targetType'])
      return [{
        alarmTargets: this.userSettingForm.target,
        channel: channelItem ? channelItem.key : '',
        targetType: targetTypeItem ? targetTypeItem.key : ''
      }]
    },
    userSetValidateStr () {
      return ['targetType', 'channel', 'target']
    }
  },
  mounted () {
    this.getPicekerInfo()
  },
  methods: {
    getPicekerInfo () {
      this.$api.earlyWarning.scheduleEnums().then(res => {
        this.targetTypeArray = res.alarmTargetTypes
        this.channelsArray = res.alarmChannels
        this.$api.earlyWarning.getRolesList().then(res => {
          res.forEach((item, index) => {
            this.rolesArray.push({ text: item.name, id: index })
          })
          this.$api.earlyWarning.getUsersList().then(res => {
            res.forEach((item, index) => {
              this.usersArray.push({ text: item.username, id: index })
            })
            if (Object.keys(this.editUserSetting).length > 0) {
              console.log('editUserSetting', this.editUserSetting)
              this.formatEditUserSetting()
            } else {
              this.getUserInfo()
            }
          }).catch(() => {})
        }).catch(() => {})
      }).catch(() => {})
    },
    getUserInfo () {
      this.$api.user.getUserProfile().then(res => {
        console.log(res)
        const user = this.usersArray.find(item => item.text === res.username)
        this.activeTargetIds.push(user.id)
        this.userSettingForm.target.push(user.text)
      }).catch(err => {
        console.log('err', err)
      })
    },
    onTargetConfirm () {
      this.userSettingForm.target.splice(0)
      const targetType = this.userSettingForm['targetType']
      if (targetType === '用户') {
        this.activeTargetIds.forEach(item => {
          const userItem = this.usersArray.find(user => user['id'] === item)
          if (userItem) {
            this.userSettingForm.target.push(userItem.text)
          }
        })
      } else if (targetType === '角色') {
        this.activeTargetIds.forEach(item => {
          const roleItem = this.rolesArray.find(user => user['id'] === item)
          if (roleItem) {
            this.userSettingForm.target.push(roleItem.text)
          }
        })
      }
      this.targetPicker = false
    },
    onUserSearch (val) {
      console.log('val', val)
      this.targetColumns[0].children.splice(0)
      this.usersArray.forEach(item => {
        if (new RegExp(val, 'ig').test(item['text'])) {
          this.targetColumns[0].children.push(item)
        }
      })
      this.$forceUpdate()
    },
    onUserSearchCancel () {
      this.targetColumns[0].children.splice(0)
      this.targetColumns[0].children.push(...this.usersArray)
      this.$forceUpdate()
    },
    onFieldPickerConfirm (value) {
      console.log(value)
      if (this.userSettingForm[this.sheetSelectType] !== value) {
        if (this.sheetSelectType === 'targetType') {
          this.userSettingForm.target.splice(0)
          this.activeTargetIds.splice(0)
          if (value === '角色') {
            this.targetPlaceholder = '请选择通知角色'
          } else if (value === '用户') {
            this.targetPlaceholder = '请选择通知用户'
          } else {
            this.targetPlaceholder = '请输入通知目标'
          }
        }
        this.userSettingForm[this.sheetSelectType] = value
      }
      this.showFieldPicker = false
    },
    clickTargetField () {
      if (this.userSettingForm['targetType'] === '角色' || this.userSettingForm['targetType'] === '用户') {
        this.targetPicker = true
      }
      return false
    },
    inputTargetField (val) {
      this.userSettingForm.target.splice(0)
      this.userSettingForm.target = val.split('、')
    },
    formatEditUserSetting () {
      this.userSettingForm['targetType'] = this.targetTypeArray.find(item => item.key === this.editUserSetting['targetType']).displayName
      this.userSettingForm['channel'] = this.channelsArray.find(item => item.key === this.editUserSetting['channel']).displayName
      this.userSettingForm.target = [...this.editUserSetting['alarmTargets']]
      this.activeTargetIds.splice(0)
      this.userSettingForm.target.forEach(item => {
        if (this.userSettingForm['targetType'] === '用户') {
          this.activeTargetIds.push(this.usersArray.find(user => user.text === item).id)
        } else if (this.userSettingForm['targetType'] === '角色') {
          this.activeTargetIds.push(this.rolesArray.find(user => user.text === item).id)
        }
      })
    }
  },
  watch: {
    formatData: {
      handler() {
        console.log()
        this.$emit('userSettingChange', this.formatData, this.userSetValidateStr)
      },
      deep: true
    },
    visible (newVal) {
      if (newVal) {
        if (Object.keys(this.editUserSetting).length > 0) {
          console.log('editUserSetting', this.editUserSetting)
          this.formatEditUserSetting()
        }
      }
    },
    showFieldPicker (newVal) {
      if (newVal) {
        window.history.pushState({ title: '接收设置', url: '#' }, '接收设置', '#')
      } else if (history.state.title === '接收设置') {
        history.back()
      }
    },
    targetPicker (newVal) {
      if (newVal) {
        window.history.pushState({ title: '通知目标', url: '#' }, '通知目标', '#')
      } else if (history.state.title === '通知目标') {
        history.back()
      }
    }
  }
}
</script>
<style lang="less" scope>
.user-setting {
  .van-cell {
    padding: 5px 16px;
  }
  .van-cell-group__title {
    font-size: 12px;
    background-color: #f8f8f8;
    padding: 4px 16px;
  }
}
.target-picker {
  .van-action-sheet__content {
    .van-tree-select {
      .van-tree-select__nav {
        display: none;
      }
      .van-tree-select__content {
        padding: 0 10%;
      }
    }
  }
}
</style>
