
<template>
  <div class="early-warning">
    <van-dialog v-model="visible" title="通知设置" show-cancel-button :beforeClose="beforeCloseDialog" :closeOnPopstate="false">
      <div :style="{ height: contentHeight + 'px', overflow: 'auto' }" ref="refDialogContent">
        <van-field v-model="taskName" label="任务名" />
        <van-field name="taskStatus" label="任务启动">
          <template #input>
            <van-switch v-model="taskStatus" size="20" />
          </template>
        </van-field>
        <ConditionSetting ref="refConditionSetting" @conditionsEditChange="conditionsEditChange" :metricsArray="metricsArray" :editConditions="editData['editConditions']" :visible="visible"/>
        <PerformSetting ref="refPerformSetting" @getCornMess="getCornMess" :editCron="editData['editCron']" :visible="visible"/>
        <UserSetting ref="refUserSetting" @userSettingChange="userSettingChange" :editUserSetting="editData['editUserSetting']" :visible="visible"/>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import PerformSetting from '@/components/speech/components/PerformSetting.vue'
import ConditionSetting from '@/components/speech/components/ConditionSetting.vue'
import UserSetting from '@/components/speech/components/UserSetting.vue'
import ConditionSettingpopup from '@/components/speech/components/ConditionSettingpopup.vue'
import RelationChoosePopup from '@/components/speech/components/RelationChoosePopup.vue'

export default {
  name: 'EarlyWarningDialog',
  data () {
    return {
      visible: false,
      taskName: '',
      conditionsEdit: [],
      userSetting: [],
      cron: '',
      performValidateStr: '',
      userSetValidateStr: '',
      taskStatus: true,
      contentHeight: 300,
      originDialogHeight: null,
      editData: {},
      originEditData: ''
    }
  },
  components: {
    ConditionSettingpopup,
    RelationChoosePopup,
    PerformSetting,
    ConditionSetting,
    UserSetting
  },
  props: {
    metricsArray: {
      type: Array,
      default: () => {
        return []
      }
    },
    keywordString: {
      type: String,
      default: ''
    },
    keywordSearchHistoryId: {
      type: String,
      default: ''
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    create (item) {
      this.originEditData = item
      this.taskName = this.keywordString + '通知'
      this.visible = true
      const _this = this
      window.onresize = function () {
        const offsetHeight = document.documentElement.clientHeight || document.body.clientHeight || _this.$root.$el.offsetHeight
        const height = offsetHeight - 250
        _this.originDialogHeight = _this.originDialogHeight || _this.$refs['refDialogContent'].scrollHeight
        _this.contentHeight = _this.originDialogHeight < height ? _this.originDialogHeight : height
      }

      this.$nextTick(() => {
        const offsetHeight = document.documentElement.clientHeight || document.body.clientHeight || this.$root.$el.offsetHeight
        const height = offsetHeight - 250
        this.originDialogHeight = this.originDialogHeight || this.$refs['refDialogContent'].scrollHeight
        this.contentHeight = this.originDialogHeight < height ? this.originDialogHeight : height
      })
      if (item) {
        console.log('item', item)
        this.taskName = item.taskName
        this.taskStatus = item.taskStatus === 'ACTIVE'
        this.editData['editConditions'] = item.alertCondition
        this.editData['editUserSetting'] = item.taskNotifications[0]
        this.editData['editCron'] = item.cron
      } else {
        this.conditionsEdit.splice(0)
        this.conditionsEdit.push({
          condition: [{
            metrics: '',
            operator: '',
            operatorCode: '',
            threshold1: '',
            threshold2: '',
            message: ''
          }],
          connection: '并且'
        })
      }
    },
    conditionsEditChange (val) {
      console.log('val', val)
      this.conditionsEdit.splice(0)
      this.conditionsEdit.push(val)
    },
    userSettingChange (val, userSetValidateStr) {
      this.userSetting.splice(0)
      this.userSetting.push(val)
      this.userSetValidateStr = userSetValidateStr
    },
    getCornMess (cron, performValidateStr) {
      this.cron = cron
      this.performValidateStr = performValidateStr
    },
    beforeCloseDialog(action, done) {
      if (action === 'cancel') {
        done()
      } else {
        const PerformSetPromise = this.$refs['refPerformSetting'].$refs['refPerformForm'].validate(this.performValidateStr)
        const userSettingPromise = this.$refs['refUserSetting'].$refs['userSettingForm'].validate(this.userSetValidateStr)
        console.log(userSettingPromise)
        userSettingPromise.then(() => {
          PerformSetPromise.then(() => {
            this.confirmWarnEdit()
            done()
          }).catch(() => {
            done(false)
          })
        }).catch(() => {
          done(false)
        })
      }
    },
    confirmWarnEdit () {
      console.log('confirmWarnEdit')
      const alertCondition = []
      const connection = this.conditionsEdit[0]['connection'] === '并且' ? 'and' : 'or'
      this.conditionsEdit[0]['condition'].forEach(item => {
        if (item['metrics'] && item['operatorCode'] && item['threshold1']) {
          if (item['operatorCode'] === 'between') {
            if (item['threshold2']) {
              alertCondition.push({
                'field': item['metrics'],
                'op': item['operatorCode'],
                'value': [item['threshold1'], item['threshold2']]
              })
            } else {
              this.$toast.fail('提交失败')
              return 0
            }
          } else {
            alertCondition.push({
              'field': item['metrics'],
              'op': item['operatorCode'],
              'value': item['threshold1']
            })
          }
        }
      })

      if (this.originEditData) {
        const params = {
          ...this.originEditData,
          'taskName': this.taskName,
          'referSearchHistoryId': this.keywordSearchHistoryId,
          'alertCondition': {
            [connection]: alertCondition
          },
          'cron': this.cron,
          'taskStatus': this.taskStatus ? 'ACTIVE' : 'INACTIVE',
          'taskNotifications': this.userSetting[0]
        }

        console.log('params', params)
        this.$api.earlyWarning.editTask(params).then(res => {
          this.$emit('editNotice')
          this.$toast.success('修改成功')
        }).catch(() => {})
      } else {
        const params = {
          'taskName': this.taskName,
          'referSearchHistoryId': this.keywordSearchHistoryId,
          'alertCondition': {
            [connection]: alertCondition
          },
          'cron': this.cron,
          'taskStatus': this.taskStatus ? 'ACTIVE' : 'INACTIVE',
          'taskNotifications': this.userSetting[0]
        }
        this.$api.earlyWarning.setScheduleTask(params).then(res => {
          console.log('res', res)
          this.$toast.success('提交成功')
        }).catch(() => {})
      }
    }
  },
  watch: {
    visible (newVal) {
      if (newVal) {
        window.history.pushState({ title: '预警编辑', url: '#' }, '预警编辑', '#')
      } else if (history.state.title === '预警编辑') {
        history.back() // 回退预警框历史
        history.back() // 回退操作选项的历史
      }
    }
  }
}
</script>
<style lang="less">

</style>
