
<template>
  <!-- 选择关系弹窗 -->
  <van-popup v-model="showConnectionPicker" position="bottom" get-container="body" safe-area-inset-bottom :close-on-popstate="true">
    <van-picker
      show-toolbar
      :columns="connectionColumns"
      @confirm="onConnectionConfirm"
      @cancel="showConnectionPicker = false"
    />
  </van-popup>
</template>
<script>
export default {
  name: 'RelationChoosePopup',
  data () {
    return {
      showConnectionPicker: false,
      connectionColumns: ['并且', '或者']
    }
  },
  computed: {
  },
  mounted () {

  },
  methods: {
    show () {
      this.showConnectionPicker = true
    },
    onConnectionConfirm (value) {
      this.$emit('onConnectionConfirm', value)
      this.showConnectionPicker = false
    }
  },
  watch: {
    showConnectionPicker (newVal) {
      if (newVal) {
        window.history.pushState({ title: '条件关系选择', url: '#' }, '条件关系选择', '#')
      } else if (history.state.title === '条件关系选择') {
        history.back()
      }
    }
  }
}
</script>
<style lang="less" scoped>
</style>
