
<template>
  <!-- 输入条件弹窗 -->
  <van-action-sheet
    v-model="showCreateCondition"
    close-on-click-action
    get-container="body"
    title="条件设置">
    <van-form @submit="onSubmitCondition" ref="refConditionPop">
      <van-field
        readonly
        clickable
        name="metrics"
        :value="conditionForm['metrics']"
        label="指标名"
        right-icon="arrow-down"
        placeholder="请选择指标"
        :rules="[{ required: true}]"
        @click="sheetSelectType='metrics'; showFieldPicker = true"
      />
      <van-field
        readonly
        clickable
        name="operator"
        :value="conditionForm['operator']"
        label="条件"
        right-icon="arrow-down"
        placeholder="请选择条件"
        :rules="[{ required: true}]"
        @click="sheetSelectType='operator';showFieldPicker = true"
      />
      <van-field
        name="threshold1"
        v-model="conditionForm['threshold1']"
        :label="ifTwoThreshold ? '下限' : '阈值'"
        placeholder="请输入或选择阈值"
        :rules="[{ required: true},{ validator, message: '下限值应该小于上限值' }]"
      >
        <template #button>
          <div style="font-size: 16px;">
            <van-icon name="arrow-down" @click="sheetSelectType='threshold1'; showFieldPicker = pickerColumns.length>0 && true"/>
          </div>
        </template>
      </van-field>
      <van-field
        name="threshold2"
        v-if="ifTwoThreshold"
        v-model="conditionForm['threshold2']"
        label="上限"
        placeholder="请输入或选择阈值"
        :rules="[{ required: true},{ validator, message: '上限值应该大于下限值' }]"
      >
        <template #button>
          <div style="font-size: 16px;">
            <van-icon name="arrow-down" @click="sheetSelectType='threshold2'; showFieldPicker = pickerColumns.length>0 && true"/>
          </div>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">确定</van-button>
      </div>
    </van-form>
    <van-popup v-model="showFieldPicker" position="bottom" get-container="body" safe-area-inset-bottom :close-on-popstate="true">
      <van-picker
        show-toolbar
        :columns="pickerColumns"
        @confirm="onFieldPickerConfirm"
        @cancel="showFieldPicker = false"
      />
    </van-popup>
  </van-action-sheet>
</template>
<script>
export default {
  name: 'ConditionSettingpopup',
  data () {
    return {
      showCreateCondition: false,
      showFieldPicker: false,
      sheetSelectType: '',
      conditionForm: {
        metrics: '',
        operator: '',
        threshold1: '',
        threshold2: '',
        message: '',
        operatorCode: ''
      },
      ifTwoThreshold: false,
      editItemIndex: null,
      connectionArray: [{ text: '大于', code: 'gt' }, { text: '小于', code: 'lt' }, { text: '等于', code: 'eq' },
        { text: '不等于', code: 'ne' }, { text: '大于等于', code: 'ge' }, { text: '小于等于', code: 'le' },
        { text: '在...和...之间', code: 'between' }]
      // { text: '在...中', code: 'in' }, { text: '不在...中', code: 'notIn' },
      // { text: '空', code: 'isNull' }, { text: '非空', code: 'notNull' }
    }
  },
  props: {
    metricsArray: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    pickerColumns () {
      if (this.sheetSelectType === 'metrics') {
        return this.metricsArray
      } else if (this.sheetSelectType === 'operator') {
        return this.connectionArray.map(item => item.text)
      } else if (this.sheetSelectType === 'threshold1' || this.sheetSelectType === 'threshold2') {
        return this.metricsArray.filter(item => item !== this.conditionForm['metrics'])
      }
      return []
    }
  },
  mounted () {
  },
  methods: {
    show (index, conditionItem) {
      console.log('conditionItem', conditionItem)
      this.editItemIndex = index
      if (conditionItem['message']) {
        this.conditionForm['metrics'] = conditionItem['metrics']
        this.conditionForm['operator'] = conditionItem['operator']
        this.conditionForm['operatorCode'] = conditionItem['operatorCode']
        this.conditionForm['threshold1'] = conditionItem['threshold1']
        this.conditionForm['threshold2'] = conditionItem['threshold2']
        this.conditionForm['message'] = conditionItem['message']
        if (this.conditionForm['operatorCode'] === 'between') {
          this.ifTwoThreshold = true
        } else {
          this.ifTwoThreshold = false
        }
      } else {
        this.conditionForm['metrics'] = this.metricsArray[0]
        this.conditionForm['operator'] = this.connectionArray[0].text
        this.conditionForm['operatorCode'] = this.connectionArray[0].code
      }
      this.showCreateCondition = true
    },
    validator () {
      if (this.conditionForm['operatorCode'] === 'between' && this.conditionForm['threshold1'] && this.conditionForm['threshold2']) {
        return Number(this.conditionForm['threshold1']) < Number(this.conditionForm['threshold2'])
      }
      return true
    },
    onFieldPickerConfirm (value) {
      if (this.sheetSelectType === 'operator') {
        if (value === '在...和...之间') {
          this.ifTwoThreshold = true
        } else {
          this.ifTwoThreshold = false
          this.$refs['refConditionPop'].validate('threshold1')
        }
        const connectionItem = this.connectionArray.find(item => item.text === value)
        if (connectionItem) {
          this.conditionForm['operatorCode'] = connectionItem.code
        }
      }
      this.conditionForm[this.sheetSelectType] = value
      this.showFieldPicker = false
    },
    onSubmitCondition () {
      if (this.conditionForm['operatorCode'] === 'between') {
        this.conditionForm['message'] = this.conditionForm['metrics'] + '在' + this.conditionForm['threshold1'] + '和' + this.conditionForm['threshold2'] + '之间'
      } else {
        this.conditionForm['message'] = this.conditionForm['metrics'] + ' ' + this.conditionForm['operator'] + ' ' + this.conditionForm['threshold1']
      }

      this.$emit('onSubmitCondition', this.conditionForm, this.editItemIndex)
      this.showCreateCondition = false
    }
  },
  watch: {
    showCreateCondition (newVal) {
      if (newVal) {
        console.log('state-showCreateCondition', history.state)
        window.history.pushState({ title: '预警条件设置', url: '#' }, '预警条件设置', '#')
        console.log('state-showCreateCondition', history.state)
      } else if (history.state.title === '预警条件设置') {
        history.back() // 回退预警条件设置历史
      }
    },
    showFieldPicker (newVal) {
      if (newVal) {
        window.history.pushState({ title: '预警条件选项', url: '#' }, '预警条件选项', '#')
      } else if (history.state.title === '预警条件选项') {
        history.back()
      }
    }
  }
}
</script>
<style lang="less" scoped>
</style>
